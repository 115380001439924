import Vue from 'vue'

const WindowInstanceMap = new Vue({
  data() {
    return {
      isMobile: null
    }
  },
  created() {
    const self = this;
    window.addEventListener('resize', e => {
      self.isMobile = window.isMobile
    })
  },
})

export default WindowInstanceMap