// window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    // window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require("jquery")

    // require('bootstrap');
} catch (e) {}

window._ = require("lodash")
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */


import Moment from 'moment';
Moment.locale( 'ro' );


window.axios = require("axios")

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
    async: false,
})

//jbox (pentru modal si mesaje)
window.jBox = require("jbox")

//tiny slider - carousel
// import { tns } from '/node_modules/tiny-slider/dist/tiny-slider.js';
// console.log(tns);
// window.tns = tns;

// window.tns = require('tiny-slider');

require("./global")

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

//vue
window.Vue = require("vue")
Object.defineProperty(window.Vue.prototype, "$_", { value: _ })
Object.defineProperty( window.Vue.prototype, '$moment', { value: Moment });
//window.Vue.prototype.$isMobile = window.isMobile
import WindowInstanceMap from "./vue-components/WindowInstanceMap";
window.Vue.mixin({
    data() {
        return {}
    },
    methods: {
        prodFavEdit(link, product, action, from = null) {
            return window.prodFavEdit(link, product, action, (from = null))
        },
        addToCart(microProductId, reloadPage, pageTitle) {
            return window.addToCart(microProductId, reloadPage, pageTitle)
        },
        slugify(text) {
            return window.slugify(text)
        },
        updateQueryStringParam(urlParams) {
            return window.updateQueryStringParam(urlParams)
        },
        showJboxNotice(content, color) {
            showJboxNotice(content, color)
        },
        GA4SelectItemEvent(
            itemListID,
            itemListName,
            itemID,
            itemName,
            productCategories,
            productPrice,
            productDiscount,
            productIndex
        ) {
            return window.GA4SelectItemEvent(
                itemListID,
                itemListName,
                itemID,
                itemName,
                productCategories,
                productPrice,
                productDiscount,
                productIndex
            )
        },
        GA4AddToWishlistEvent(
            itemListID,
            itemListName,
            itemID,
            itemName,
            productCategories,
            productPrice,
            productDiscount,
            ecommerceValue
        ) {
            return window.GA4AddToWishlistEvent(
                itemListID,
                itemListName,
                itemID,
                itemName,
                productCategories,
                productPrice,
                productDiscount,
                ecommerceValue
            )
        },
        GA4ViewItemListEvent(
          itemListID,
          itemListName,
          items
        ) {
            return window.GA4ViewItemListEvent(
              itemListID,
              itemListName,
              items
            )
        }
    },
})

// components
window.Vue.component("vue-public-products-list", require("./vue-components/ProductsList.vue").default)

Vue.filter("truncate", function(value, size) {
    if (!value) return ""
    value = value.toString()

    if (value.length <= size) {
        return value
    }
    return value.substr(0, size) + "..."
})



new window.Vue({
    el: "#app",
})
