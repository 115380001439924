<template>
    <div class="category-page">
        <div class="col-left">
            <div class="category-toc" v-if="mainCateg">
                <p class="h4 category-toc__title" v-if="mainCateg">{{ mainCateg.name }}</p>
                <ul>
                    <li v-for="(categ, key) in leftCategs" :key="`categs-${key}`">
                        <a :href="routes.mainCategRoute + '/' + categ.slug" :title="categ.name" v-if="mainCateg.parent_id !== categ.parent_id"
                            ><strong>{{ categ.name }}</strong></a
                        >
                        <a :href="getBaseRoute(routes.mainCategRoute) + categ.slug" :title="categ.name" v-else
                            ><strong>{{ categ.name }}</strong></a
                        >
                        <ul>
                            <li v-for="(subcateg, key_subcateg) in categ.subcategories" :key="`subcategs-${key}-${key_subcateg}`">
                                <a :href="routes.mainCategRoute + '/' + categ.slug + '/' + subcateg.slug" :title="subcateg.name">{{ subcateg.name }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="filters-container js-filters">
                <div class="filter-box__header">
                    <span>{{ paginationData.total }} produse</span>
                    <button class="btn btn--primary js-hide-filters">Afișează</button>
                </div>

                <div class="filter-box" v-if="showDeleteFilters">
                    <a href="javascript:void(0)" @click="resetFilters()" class="filters-reset" title="Șterge toate filtrele">
                        Șterge toate filtrele
                        <span class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100px" height="100px">
                                <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" />
                            </svg>
                        </span>
                    </a>
                </div>

                <div class="filter-box" v-if="priceMin < priceMax">
                    <div class="filter-box__title">
                        Preț
                    </div>
                    <div class="filter-box__content">
                        <div class="rangeslider">
                            <div id="sliderPrice" ref="sliderPrice"></div>
                            <div class="rangeslider-value"><span class="js-rangeslider-price-value"></span> Lei</div>
                        </div>
                    </div>
                </div>

                <div class="filter-box"
                     v-for="(filter, key) in flatBrandsFilter"
                     :class="{ collapsed: !localBrandsFilter.find((v) => v.id === filter.id).collapsed }"
                     :key="`filter-brand-${key}`"
                >
                    <div class="filter-box__title" @click="toggleBrandCollapse(filter.id)">
                        {{ filter.name }}
                        <span class="filter-box__toggle">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100px" height="100px">
                                <path
                                        d="M 24.96875 13 C 24.449219 13.007813 23.953125 13.21875 23.585938 13.585938 L 3.585938 33.585938 C 3.0625 34.085938 2.851563 34.832031 3.035156 35.535156 C 3.21875 36.234375 3.765625 36.78125 4.464844 36.964844 C 5.167969 37.148438 5.914063 36.9375 6.414063 36.414063 L 25 17.828125 L 43.585938 36.414063 C 44.085938 36.9375 44.832031 37.148438 45.535156 36.964844 C 46.234375 36.78125 46.78125 36.234375 46.964844 35.535156 C 47.148438 34.832031 46.9375 34.085938 46.414063 33.585938 L 26.414063 13.585938 C 26.03125 13.203125 25.511719 12.992188 24.96875 13 Z"
                                />
                            </svg>
                        </span>
                    </div>
                    <div class="filter-box__content" v-if="filter.display_type === 'radio' && filter.collapsed">
                        <label v-for="(val, key_val) in filter.values" :key="`filter-${key}-val-${key_val}`">
                            <input type="checkbox" v-model="val.checked" @change="updateFilters()"/> {{ val.value }}
                            <span>({{ val.count }})</span>
                        </label>
                    </div>
                </div>
                <template v-if="searchWords || label">
                    <div class="filter-box"
                         v-for="(filter, key) in flatCategoryFilter"
                         :class="{ collapsed: !localCategoriesFilter.find((v) => v.id === filter.id).collapsed  }"
                         :key="`filter-category-filter-${key}`"
                    >
                        <div class="filter-box__title" @click="toggleCategoryCollapse(filter.id)">
                            {{ filter.name }}
                            <span class="filter-box__toggle">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100px" height="100px">
                                <path
                                        d="M 24.96875 13 C 24.449219 13.007813 23.953125 13.21875 23.585938 13.585938 L 3.585938 33.585938 C 3.0625 34.085938 2.851563 34.832031 3.035156 35.535156 C 3.21875 36.234375 3.765625 36.78125 4.464844 36.964844 C 5.167969 37.148438 5.914063 36.9375 6.414063 36.414063 L 25 17.828125 L 43.585938 36.414063 C 44.085938 36.9375 44.832031 37.148438 45.535156 36.964844 C 46.234375 36.78125 46.78125 36.234375 46.964844 35.535156 C 47.148438 34.832031 46.9375 34.085938 46.414063 33.585938 L 26.414063 13.585938 C 26.03125 13.203125 25.511719 12.992188 24.96875 13 Z"
                                />
                            </svg>
                        </span>
                        </div>
                        <div class="filter-box__content" v-if="filter.display_type === 'radio' && filter.collapsed">
                            <label v-for="(val, key_val) in filter.values" :key="`filter-${key}-val-${key_val}`">
                                <input type="checkbox" v-model="val.checked" @change="updateFilters()"/> {{ val.value }}
                                <span>({{ val.count }})</span>
                            </label>
                        </div>
                    </div>

                </template>

                <div class="filter-box" :class="{ collapsed: !filter.collapsed }" v-for="(filter, key) in filters"
                     :key="`filter-${key}`"
                     v-if="filter.display_type !== 'slide' || (filter.display_type === 'slide' && Object.keys(filter.values).length > 1)">
                    <div class="filter-box__title" @click="filter.collapsed = !filter.collapsed">
                        {{ filter.name }}
                        <span class="filter-box__toggle">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100px" height="100px">
                                <path
                                        d="M 24.96875 13 C 24.449219 13.007813 23.953125 13.21875 23.585938 13.585938 L 3.585938 33.585938 C 3.0625 34.085938 2.851563 34.832031 3.035156 35.535156 C 3.21875 36.234375 3.765625 36.78125 4.464844 36.964844 C 5.167969 37.148438 5.914063 36.9375 6.414063 36.414063 L 25 17.828125 L 43.585938 36.414063 C 44.085938 36.9375 44.832031 37.148438 45.535156 36.964844 C 46.234375 36.78125 46.78125 36.234375 46.964844 35.535156 C 47.148438 34.832031 46.9375 34.085938 46.414063 33.585938 L 26.414063 13.585938 C 26.03125 13.203125 25.511719 12.992188 24.96875 13 Z"
                                />
                            </svg>
                        </span>
                    </div>
                    <div class="filter-box__content" v-if="filter.display_type === 'radio' && filter.collapsed">
                        <label v-for="(val, key_val) in filter.values" :key="`filter-${key}-val-${key_val}`">
                            <input type="checkbox" v-model="val.checked" @change="updateFilters()"/> {{ val.value }}
                            <span>({{ val.count }})</span>
                        </label>
                    </div>
                    <div class="filter-box__content" v-if="filter.display_type === 'select' && filter.collapsed">
                        <select class="form-control" v-model="filter.selected" @change="setSelectFilter(key)">
                            <option :value="null">Alege {{ filter.name }}</option>
                            <option :value="val.id" v-for="(val, key_val) in filter.values"
                                    :key="`filter-${key}-val-${key_val}`">{{ val.value }}({{ val.count }})
                            </option>
                        </select>
                    </div>
                    <div class="filter-box__content" v-if="filter.display_type === 'slide'"
                         :style="'display: ' + (filter.collapsed ? 'block' : 'none')">
                        <div class="rangeslider">
                            <div :id="`slider-attr-${filter.id}`" :ref="`slider-attr-${filter.id}`"></div>
                            <div class="rangeslider-value"><span
                                    :class="`js-rangeslider-attr-${filter.id}-value`"></span></div>
                        </div>
                    </div>
                </div>
                <div class="filter-box__end">
                    <p>Sfârșitul listei de filtre</p>
                </div>
            </div>
        </div>
        <div class="col-right">
            <p class="category-banner" v-if="mainCateg && mainCateg.banner_media">
                <a :href="mainCateg.banner_url">
                    <img :src="mainCateg.banner_media" alt=""/>
                </a>
            </p>
            <p class="category-banner" v-else-if="label && label.banner_media">
                <a :href="label.banner_url">
                    <img :src="label.banner_media" alt=""/>
                </a>
            </p>

            <div class="category-header">
                <h1 class="h2 category-header__title" v-if="this.searchWords">
                    "{{ this.searchWords.split("+").join(" ")}}"
                    <span>- {{ paginationData.total }} rezultate</span>
                </h1>

                <h1 class="h2 category-header__title" v-if="mainCateg">
                    {{ mainCateg.name }} <span>{{ paginationData.total }} produse</span>
                </h1>

                <h1 class="h2 category-header__title" v-if="label">
                    {{ label.name }} <span>{{ paginationData.total }} produse</span>
                </h1>

                <div v-if="mainCateg">
                    <div v-if="isMobileView" class="category-header__short-description">
                        <div class="" v-if="!showShortDescription" :inner-html.prop="mainCateg.short_description | truncate(100)"></div>
                        <a
                            @click="
                                showShortDescription = !showShortDescription
                                showMore = false
                            "
                            v-if="mainCateg.short_description && mainCateg.short_description.length > 100 && showMore"
                            href="javascript:void(0)"
                            >vezi mai mult</a
                        >
                        <div v-if="showShortDescription && !showMore" v-html="mainCateg.short_description"></div>
                    </div>
                    <div v-else v-html="mainCateg.short_description"></div>
                </div>
                <div v-else-if="label">
                    <div v-if="isMobileView" class="category-header__short-description">
                        <div class="" v-if="!showShortDescription" :inner-html.prop="label.short_description | truncate(100)"></div>
                        <a
                                @click="
                                showShortDescription = !showShortDescription
                                showMore = false
                            "
                                v-if="label.short_description && label.short_description.length > 100 && showMore"
                                href="javascript:void(0)"
                        >vezi mai mult</a
                        >
                        <div v-if="showShortDescription && !showMore" v-html="label.short_description"></div>
                    </div>
                    <div v-else v-html="label.short_description"></div>
                </div>
                <div v-else-if="this.searchWords && this.searchWords.length > 0">
                    <div v-if="isMobileView" class="category-header__short-description">
                        <div class="" v-if="!showShortDescription"
                             :inner-html.prop="searchShortDescription | truncate(100)"></div>
                        <a
                                @click="
                                showShortDescription = !showShortDescription
                                showMore = false
                            "
                                v-if="searchShortDescription.length > 100 && showMore"
                                href="javascript:void(0)"
                        >vezi mai mult</a
                        >
                        <div v-if="showShortDescription && !showMore" v-html="searchShortDescription"></div>
                    </div>
                    <p v-else v-html="searchShortDescription"></p>
                </div>
                <div class="category-header__actions">
                    <div class="form-inline" id="js-products-list">
                        <div class="form-group category-header__filter-trigger">
                            <small class="small-only">Aplică filtre</small>
                            <a href="javascript:;" class="btn btn--secondary btn--block js-filters-toggle" title="Filtrează">Filtrează</a>
                        </div>
                        <div class="form-group category-header__order">
                            <small class="small-only">Sortează produsele</small>
                            <label class="form-label">Ordonează după:</label>
                            <select name="" v-model="orderBy" class="form-control" @change="updateFilters()">
                                <option value="relevance">Cele mai populare</option>
                                <option value="price_asc">Preț crescător</option>
                                <option value="price_desc">Preț descrescător</option>
                                <option value="name_asc">Nume A-Z</option>
                                <option value="name_desc">Nume Z-A</option>
                                <option value="new">Cele mai noi</option>
                                <option value="review_count">Recenzii</option>
                            </select>
                        </div>
                        <div class="form-group category-header__products" @change="updateFilters()">
                            <label class="form-label">Produse:</label>
                            <select name="" v-model="perPage" class="form-control">
                                <option value="20">20 pe pagină</option>
                                <option value="40">40 pe pagină</option>
                                <option value="60">60 pe pagină</option>
                                <option value="100">100 pe pagină</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="category-list" v-if="products.length > 0">
                <div class="list-item" v-for="(mp, key) in products" :key="`mp-list-${key}`">
                    <div class="list-item__flags" v-if="mp.final_cut_sell_price > mp.final_sell_price || checkFreeShipping(mp).free">
                        <template v-if="mp.final_cut_sell_price > mp.final_sell_price && $moment().isBetween($moment('2023-11-01 08:00:00'), $moment('2023-12-01 00:30:00'))">
                            <span class="list-item__discount-black-friday" v-if="mp.disscount_display_type === 'percent'">-{{ parseFloat(((mp.final_cut_sell_price - mp.final_sell_price) / mp.final_cut_sell_price) * 100).toFixed(0) }}% BLACK FRIDAY</span>
                            <span class="list-item__discount-black-friday"  v-else>-{{ parseFloat(mp.final_cut_sell_price - mp.final_sell_price).toFixed(0) }} Lei BLACK FRIDAY</span>
                        </template>

                        <template v-else-if="mp.final_cut_sell_price > mp.final_sell_price">
                            <span class="list-item__discount" v-if="mp.disscount_display_type === 'percent'">-{{ parseFloat(((mp.final_cut_sell_price - mp.final_sell_price) / mp.final_cut_sell_price) * 100).toFixed(0) }}%</span>
                            <span class="list-item__discount"  v-else>-{{ parseFloat(mp.final_cut_sell_price - mp.final_sell_price).toFixed(0) }} Lei</span>
                        </template>
                        <span class="list-item__free-shipping" v-if="checkFreeShipping(mp).free">Transport gratuit</span>
                    </div>

                    <!-- if logged as admin -->
                    <template v-if="isAdmin">
                        <div class="list-item__admin">
                            <a :href="productEditLink(mp)" class="btn-admin" title="Edit Produs" target="_blank">
                                <svg width="16" height="16" viewBox="0 0 16 16">
                                    <path
                                        d="M15.84 4.32001L11.68 0.160011C11.44 -0.079989 11.04 -0.079989 10.8 0.160011L0.4 10.56C0.16 10.8 0 11.2 0 11.6V15.36C0 15.68 0.32 16 0.64 16H4.4C4.8 16 5.12 15.84 5.44 15.6L13.44 7.60001L15.76 5.20001C16.08 4.96001 16.08 4.56001 15.84 4.32001ZM4.56 14.72C4.56 14.72 4.48 14.8 4.4 14.8H1.28V11.68C1.28 11.6 1.28 11.6 1.36 11.52L8.96 3.92001L12.24 7.20001L4.56 14.72ZM13.04 6.24001L9.76 2.96001L11.2 1.52001L14.48 4.80001L13.04 6.24001Z"
                                    />
                                </svg>
                            </a>
                        </div>
                    </template>
                    <!-- endif -->
                    <template v-if="isAdmin">
                        <!-- if logged as admin -->
                        <div class="list-item__admin">
                            <a :href="productEditLink(mp)" class="btn-admin" title="Edit Produs" target="_blank">
                                <svg width="16" height="16" viewBox="0 0 16 16">
                                    <path
                                        d="M15.84 4.32001L11.68 0.160011C11.44 -0.079989 11.04 -0.079989 10.8 0.160011L0.4 10.56C0.16 10.8 0 11.2 0 11.6V15.36C0 15.68 0.32 16 0.64 16H4.4C4.8 16 5.12 15.84 5.44 15.6L13.44 7.60001L15.76 5.20001C16.08 4.96001 16.08 4.56001 15.84 4.32001ZM4.56 14.72C4.56 14.72 4.48 14.8 4.4 14.8H1.28V11.68C1.28 11.6 1.28 11.6 1.36 11.52L8.96 3.92001L12.24 7.20001L4.56 14.72ZM13.04 6.24001L9.76 2.96001L11.2 1.52001L14.48 4.80001L13.04 6.24001Z"
                                    />
                                </svg>
                            </a>
                        </div>
                        <!-- endif -->
                    </template>
                    <div v-if="isAuth">
                        <div class="list-item__fav" v-if="favouritesProductsIds.includes(mp.id)" @click="removeFromFav(mp.id)">
                            <span class="icon">
                                <svg width="22" height="20" viewBox="0 0 22 20" class="on">
                                    <use xlink:href="#svg-favorites-on"></use>
                                </svg>
                            </span>
                        </div>
                        <div class="list-item__fav" v-else @click="addToFav(mp.id)">
                            <span class="icon">
                                <svg width="22" height="20" viewBox="0 0 22 20" class="off">
                                    <use xlink:href="#svg-favorites-off"></use>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div v-else>
                        <div class="list-item__fav" @click="addToFav(mp.id)">
                            <span class="icon">
                                <svg width="22" height="20" viewBox="0 0 22 20" class="off">
                                    <use xlink:href="#svg-favorites-off"></use>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="list-item__image">
                        <a :href="mp.detail_route" :title="mp.name" @click.prevent="selectItemEvent(mp, mp.detail_route, key+1)">
                            <img width="250" height="250" v-if="mp.public_list_media" :alt="mp.product_name + mp.attr_values_string"
                                 :title="mp.product_name + mp.attr_values_string" loading="lazy"
                                 :src="mp.public_list_media"/>
                            <img width="250" height="250" v-else src="/img/no-image.jpg" :alt="mp.product_name + mp.attr_values_string"
                                 :title="mp.product_name + mp.attr_values_string"/>
                        </a>
                    </div>
                    <div class="list-item__rating">
                        <a :href="mp.detail_route + '#reviewSection'" :title="mp.name" @click.prevent="selectItemEvent(mp, mp.detail_route + '#reviewSection', key+1)">
                            <div class="product-reviews">
                                <div class="product-rating">
                                    <span class="product-rating__bg">
                                        <span class="product-rating__stars" :style="`width: calc(100% / 5 * ${(mp.reviews_approved_sum || 0) / (mp.reviews_approved_count || 1)})`"></span>
                                    </span>
                                    <div class="product-rating__num">{{ parseFloat((mp.reviews_approved_sum || 0) / (mp.reviews_approved_count || 1)).toFixed(2) }}</div>
                                </div>
                                <div class="product-rating__reviews">({{ mp.reviews_approved_count }})</div>
                            </div>
                        </a>
                    </div>
                    <h2 class="list-item__title">
                        <a :href="mp.detail_route" :title="mp.name" @click.prevent="selectItemEvent(mp, mp.detail_route, key+1)"> {{ mp.name }} </a>
                    </h2>
                    <p class="product-return-days" v-if="mp.nr_of_days_to_return > 0">
                        *Retur {{ mp.nr_of_days_to_return }} zile
                    </p>

                    <div class="list-item__stock">
                        <span class="product-stock product-stock--yes" v-if="mp.stock == null || mp.stock > 0">în stoc</span>
                        <span class="product-stock product-stock--order" v-else-if="mp.stock <= 0 && mp.on_order">la comandă</span>
                        <span class="product-stock product-stock--no" v-else-if="mp.stock <= 0 && !mp.on_order">stoc indisponibil</span>
                    </div>
                    <div class="list-item__price">
                        <div class="product-price">
                            <div class="product-price__cut" v-if="mp.final_cut_sell_price > mp.final_sell_price">
                                <div class="product-price__old">
                                    {{
                                        parseInt(mp.final_cut_sell_price)
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    }}<sup>{{ String(parseFloat(mp.final_cut_sell_price - parseInt(mp.final_cut_sell_price)).toFixed(2)).substring(2) }}</sup> Lei
                                </div>
                                <div class="product-price__save">
                                    (-{{
                                        parseInt(mp.final_cut_sell_price - mp.final_sell_price)
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                    }}<sup>{{ String(parseFloat(mp.final_cut_sell_price - mp.final_sell_price - parseInt(mp.final_cut_sell_price - mp.final_sell_price)).toFixed(2)).substring(2) }}</sup> Lei)
                                </div>
                            </div>
                            <div class="product-price__main">
                                {{
                                    parseInt(mp.final_sell_price)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                                }}<sup>{{ String(parseFloat(mp.final_sell_price - parseInt(mp.final_sell_price)).toFixed(2)).substring(2) }}</sup> Lei
                                <span class="product-price__unit">/ {{ mp.measure_unit.name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="list-item__actions">
                        <a href="javascript:void(0)" title="Adaugă în coș" @click="addToCart(mp.id, false, dataPageTitle)" class="btn btn--cart" v-if="(mp.stock > 0 || mp.stock == null || mp.on_order) && mp.final_sell_price > 0"><span>Adaugă în coș</span></a>
                        <a href="javascript:void(0)" title="Adaugă la favorite" @click="addToFav(mp.id)" class="btn btn--fav" v-else-if="isAuth && !favouritesProductsIds.includes(mp.id)">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100px" height="100px">
                                <path
                                    d="M 15 7 C 7.832031 7 2 12.832031 2 20 C 2 34.761719 18.695313 42.046875 24.375 46.78125 L 25 47.3125 L 25.625 46.78125 C 31.304688 42.046875 48 34.761719 48 20 C 48 12.832031 42.167969 7 35 7 C 30.945313 7 27.382813 8.925781 25 11.84375 C 22.617188 8.925781 19.054688 7 15 7 Z M 15 9 C 18.835938 9 22.1875 10.96875 24.15625 13.9375 L 25 15.1875 L 25.84375 13.9375 C 27.8125 10.96875 31.164063 9 35 9 C 41.085938 9 46 13.914063 46 20 C 46 32.898438 31.59375 39.574219 25 44.78125 C 18.40625 39.574219 4 32.898438 4 20 C 4 13.914063 8.914063 9 15 9 Z"
                                ></path>
                            </svg>
                            <span>Adaugă la favorite</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="content-box" v-if="products.length === 0">
                <p>
                    Din păcate nu a fost găsit nici un produs conform cu cerințele dvs.
                </p>
            </div>
            <div class="category-list-footer" v-if="paginationData.total > 0">
                <div class="category-list-footer__desktop">
                    <p>
                        <strong>{{ paginationData.from }} - {{ paginationData.to }}</strong> din <strong>{{ paginationData.total }}</strong> produse
                    </p>
                    <ul class="pagination" v-html="pagination"></ul>
                </div>
                <div class="category-list-footer__mobile">
                    <p>
                        <span>{{ paginationData.current_page }} din {{ paginationData.last_page }}</span>
                    </p>
                    <ul class="pagination" v-html="pagination"></ul>
                </div>
            </div>
            <div>
                <div v-if="mainCateg" v-html="mainCateg.description"></div>
                <div v-if="label" v-html="label.description"></div>
                <div v-if="Object.keys(dataKeywordLabelLinks).length > 0">
                    <h3 class="product-subtitle">
                        Alții au căutat și:
                    </h3>
                    <div class="tag-list">
                        <a v-for="(keywordLabelLink, name) in dataKeywordLabelLinks" :href="keywordLabelLink" rel="noindex">{{ name }}</a>
                    </div>
                </div>
                <div v-if="mainCateg && mainCateg.faq && mainCateg.faq.length > 0">
                    <h2 class="product-subtitle" style="margin-bottom: 5px">Întrebări frecvente despre {{mainCateg.name}}</h2>
                    <div class="container-faq">
                        <template v-for="(faq, key) in mainCateg.faq">
                            <h3 class="accordion">{{faq.question}}</h3>
                            <div class="panel" v-html="faq.answer"></div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import WindowInstanceMap from "./WindowInstanceMap.js"

export default {
  name: "ProductsList",
  props: [
    'dataPageTitle',
    'dataPagination',
    'dataProducts',
    'dataFilters',
    'dataBrandsFilters',
    'dataCategoryFilters',
    'extradataPagination',
    'totalProducts',
    'mainCateg',
    'leftCategs',
    'dataOrderby',
    'dataPerpage',
    'priceMin',
    'priceMax',
    'fpriceMin',
    'fpriceMax',
    'routes',
    'isAuth',
    'dataFavouritesProductsIds',
    'dataKeywordLabelLinks',
    'label',
    'searchWords',
    'isAdmin',
    'searchShortDescription',
    'dataBreadcrumbs'
  ],
  data() {
    return {
      favouritesProductsIds: [],
      showDeleteFilters: false,
      pagination: '',
      products: [],
      filters: [],
      orderBy: 'relevance',
      perPage: 20,
      paginationData: {
        from: 0,
        to: 0,
        total: 0
      },
      sliderPriceData: {
        startMin: 0,
        startMax: 100,
        min: 0,
        max: 100,
        start: 0,
        step: 1,
        format: wNumb({
          decimals: 0
        }),
      },
      fMaxPrice: null,
      fMinPrice: null,
      searchAfter: null,
      showShortDescription: false,
      showMore: true,
      isMobileView: false,
      localBrandsFilter: this.dataBrandsFilters,
      localCategoriesFilter: this.dataCategoryFilters,
    }
  },
  computed: {
    flatBrandsFilter() {
      let brands = [];
      let output = [];
      let values = [];
      if (Object.keys(this.dataBrandsFilters).length) {
        for (let [key, value] of Object.entries(this.dataBrandsFilters)) {
          if (!brands.includes('brand')) {
            brands.push('brand')
            output.push(value)
            let objectValue = value.values
            for (let [KEY, VALUE] of Object.entries(objectValue)) {
              values.push(VALUE)
            }
          } else {
            let findBrandFilterIndex = output.findIndex(filter => filter.name == 'Brand')
            if (findBrandFilterIndex !== -1) {
              let objectValue = value.values
              for (let [KEY, VALUE] of Object.entries(objectValue)) {
                values.push(VALUE)
              }
            }
          }
        }
        delete output[0]['values']
        output[0]['values'] = values
        return output
      }
      return [];
    },
    flatCategoryFilter() {
      let brands = [];
      let output = [];
      let values = [];
      if (Object.keys(this.dataCategoryFilters).length) {
        for (let [key, value] of Object.entries(this.dataCategoryFilters)) {
          if (!brands.includes('category')) {
            brands.push('category')
            output.push(value)
            let objectValue = value.values
            for (let [KEY, VALUE] of Object.entries(objectValue)) {
              values.push(VALUE)
            }
          } else {
            let findBrandFilterIndex = output.findIndex(filter => filter.name == 'Categorii')
            if (findBrandFilterIndex !== -1) {
              let objectValue = value.values
              for (let [KEY, VALUE] of Object.entries(objectValue)) {
                values.push(VALUE)
              }
            }
          }
        }
        delete output[0]['values']
        output[0]['values'] = values
        return output
      }
      return [];
    }
  },
  created() {
    let vm = this
    window.addEventListener("popstate", function () {
      let queryString = window.location.search
      if (queryString && vm.mainCateg) {
        queryString += '&category=' + vm.mainCateg.id
      } else if (queryString === '' && vm.mainCateg) {
        queryString += '?category=' + vm.mainCateg.id
      }
      if (vm.label) {
        if (queryString.length > 0) {
          queryString += '&label=' + vm.label.id
        } else {
          queryString += '?label=' + vm.label.id
        }
      }
      if (vm.searchWords) {
        if (queryString.length > 0) {
          queryString += '&q=' + vm.searchWords
        } else {
          queryString += '?q=' + vm.searchWords
        }
      }
      vm.getProducts(vm.routes.productsSearch + queryString)
    }, false);
    this.favouritesProductsIds = _.cloneDeep(this.dataFavouritesProductsIds);
    this.products = _.cloneDeep(this.dataProducts);
    this.pagination = _.cloneDeep(this.dataPagination);
    this.filters = _.cloneDeep(_.sortBy(this.dataFilters, 'ord'));
    this.filters.forEach((val, index) => {
      this.filters[index].values = _.sortBy(val.values, 'ord')
    })
    this.paginationData = _.cloneDeep(this.extradataPagination);
    this.orderBy = _.cloneDeep(this.dataOrderby);
    this.perPage = _.cloneDeep(this.dataPerpage);


    this.sliderPriceData.min = this.priceMin
    this.sliderPriceData.startMin = this.fpriceMin > 0 ? this.fpriceMin : this.priceMin
    this.sliderPriceData.max = this.priceMax
    this.sliderPriceData.startMax = this.fpriceMax > 0 ? this.fpriceMax : this.priceMax
    this.isMobileView = window.isMobile
    window.addEventListener("resize", this.resize);
  },
  mounted() {
    this.checkDeleteFilters()
    let self = this
    if (this.priceMin < this.priceMax) {
      noUiSlider.create(this.$refs.sliderPrice, {
        start: [this.sliderPriceData.startMin, this.sliderPriceData.startMax],
        step: this.sliderPriceData.step,
        range: {
          'min': this.sliderPriceData.min,
          'max': this.sliderPriceData.max
        }
      });

      this.$refs.sliderPrice.noUiSlider.on('update', (values, handle) => {
        this[handle ? 'maxRange' : 'minRange'] = parseInt(values[handle]);
        let sliderValue = document.querySelector('.js-rangeslider-price-value');
        sliderValue.innerHTML = values[0] + ' - ' + values[1];
      });
      this.$refs.sliderPrice.noUiSlider.on('change', function (values, handle) {
        self.fMinPrice = Math.floor(values[0])
        self.fMaxPrice = Math.ceil(values[1])
        self.updateFilters()
      });
      Object.keys(this.filters).forEach((f_key) => {
        if (this.filters[f_key].display_type === 'slide' && Object.keys(this.filters[f_key].values).length > 1) {
          this.initSliderFilter(this.filters[f_key])
        }
      })
    }

    this.initPageChangeListener()
    this.viewItemListEvent()
  },
  methods: {
    addToFav(productId) {
      let vm = this
      activateLoading()
      let mainCategoryName = '';
      let mainCategorySlug = '';
      if (this.mainCateg && this.mainCateg.length > 0) {
        mainCategoryName = this.mainCateg.name
        mainCategorySlug = this.slugify(this.mainCateg.name)
      } else if (this.label) {
        mainCategoryName = this.label.name;
        mainCategorySlug = this.label.slug;
      } else if (this.searchWords) {
        mainCategoryName = 'Cautare dupa "' + this.searchWords + '"';
        mainCategorySlug = '';
      }
      axios.post('/prod-fav-edit',
        {
          product: productId,
          action: 'add'
        }
      )
        .then(function (response) {
          let res = response.data.data;

          if (res.redirect_to_login_link) {
            window.location.href = res.redirect_to_login_link
            return
          }

          vm.favouritesProductsIds.push(productId);

          let discount = 0;
          let price = res.microProduct.final_sell_price;
          if (res.microProduct.final_cut_sell_price > res.microProduct.final_sell_price) {
            discount = res.microProduct.final_cut_sell_price - res.microProduct.final_sell_price;
            price = res.microProduct.final_cut_sell_price;
          }
          GA4AddToWishlistEvent(
            mainCategorySlug,
            mainCategoryName,
            res.microProduct.sku_code,
            res.microProduct.name,
            res.microProductBreadcrumbs,
            price,
            discount,
            res.subtotalWishlistFloat
          );

          $('#js-favourite-header').html(res.favourite_header_html);
          showJboxNotice('Produsul a fost adăugat la favorite', 'green');
          dezactivateLoading()
        })
        .catch(function (error) {
          // handle error
          dezactivateLoading()
          showError(error)
        })
    },
    removeFromFav(productId) {
      let vm = this
      activateLoading()
      axios.post('/prod-fav-edit',
        {
          product: productId,
          action: 'remove'
        }
      )
        .then(function (response) {
          let res = response.data.data;
          let index = vm.favouritesProductsIds.indexOf(productId);
          if (index >= 0) {
            vm.favouritesProductsIds.splice(index, 1);
          }
          $('#js-favourite-header').html(res.favourite_header_html);
          showJboxNotice('Produsul a fost șters de la favorite', 'blue');
          dezactivateLoading()
        })
        .catch(function (error) {
          // handle error
          dezactivateLoading()
          showError(error)
        })
    },
    checkDeleteFilters() {
      let urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('page')
      urlParams.delete('orderBy')
      urlParams.delete('perPage')
      this.showDeleteFilters = urlParams.toString().length > 0;
    },
    initSliderFilter(filter) {
      let ranges = []
      let jsData = []
      let start = 0
      let startValue = 0

      Object.keys(filter.values).forEach((v_key) => {
        let val = filter.values[v_key].value.replace(/^\D+/g, '')
        if (filter.values[v_key].checked === true) {
          startValue = val
        }
        ranges.push(val)
        jsData[val] = {
          attr_id: filter.id,
          value_id: filter.values[v_key].id,
          value: filter.values[v_key].value,
          value_key: v_key
        }
      })
      ranges = ranges.sort((a, b) => (a.ord > b.ord) ? 1 : ((b.ord > a.ord) ? -1 : 0))
      start = ranges.indexOf(startValue)

      noUiSlider.create(this.$refs["slider-attr-" + filter.id][0], {
        start: start,
        step: 1,
        format: wNumb({
          decimals: 0
        }),
        range: {
          min: 0,
          max: ranges.length - 1
        }
      })

      this.$refs["slider-attr-" + filter.id][0].noUiSlider.on('update', function (values, handle) {
        let range = ranges;
        let sliderValue = document.querySelector('.js-rangeslider-attr-' + filter.id + '-value');
        sliderValue.innerHTML = range[values[handle]];
      });

      let self = this
      this.$refs["slider-attr-" + filter.id][0].noUiSlider.on('change', function (values, handle) {
        let range = ranges;
        let dataValues = jsData
        let data = dataValues[range[values[handle]]]
        self.updateFilterSlide(filter, data.value_key)
      });

    },
    updateFilterSlide(filter, value_key) {
      this.filters[filter.id].values[value_key].checked = true
      this.updateFilters()
    },
    getProducts(link, scrollToSection = false, metaTitle = false) {
      activateLoading()
      axios.get(link)
        .then((response) => {
          this.products = _.cloneDeep(response.data.dataMicroProducts)
          this.pagination = _.cloneDeep(response.data.dataLinksTemplate)
          this.filters = _.cloneDeep(_.sortBy(response.data.dataAttrsFilter, 'ord'));
          this.filters.forEach((val, index) => {
            this.filters[index].values = _.sortBy(val.values, 'ord')
          })
          this.paginationData = _.cloneDeep(response.data.paginationData)
          this.orderBy = _.cloneDeep(response.data.orderBy)
          this.perPage = _.cloneDeep(response.data.perPage)
          if (scrollToSection) {
            document.getElementById('js-products-list').scrollIntoView()
          }

          // change meta data
          if (metaTitle) {
            const titleEl = document.querySelector('head title');
            const ogTitleEl = document.querySelector('meta[property="og:title"]');
            if (this.mainCateg) {
              document.title = this.mainCateg.name + ' ' + metaTitle.join(', ') + ' - MESTERESTI'
              ogTitleEl.setAttribute('content', this.mainCateg.name + ' ' + metaTitle.join(', ') + ' - MESTERESTI')
            } else if (this.label) {
              document.title = this.label.name + ' ' + metaTitle.join(', ') + ' - MESTERESTI'
              ogTitleEl.setAttribute('content', this.label.name + ' ' + metaTitle.join(', ') + ' - MESTERESTI')
            } else {
              document.title = `Cauti ${this.searchWords} ${metaTitle.join(', ')} ?  - MESTERESTI`
              ogTitleEl.setAttribute('content', `Cauti ${this.searchWords} ${metaTitle.join(', ')} ?  - MESTERESTI`)
            }
          }
          this.checkDeleteFilters()
          this.viewItemListEvent()
          dezactivateLoading()
        }, (error) => {
          new jBox('Notice', {
            content: 'A apărut o eroare! <br>Vă rugăm să încercați din nou mai tarziu!',
            color: 'red'
          });
          this.checkDeleteFilters()
          dezactivateLoading()
        });
    },
    initPageChangeListener() {
      let buttons = document.querySelectorAll('.js-link-button');
      buttons.forEach((b) => {
        b.onclick = (e) => {
          const url = new URL(e.target.getAttribute('data-link'));
          const pageQueryParam = new URLSearchParams(url.search);
          const page = pageQueryParam.get('page') || 1;
          // const urlParams = new URLSearchParams(window.location.search);
          // console.log(window.location.search, 'window.location.search')
          pageQueryParam.append('page', page)
          let params = [];
          pageQueryParam.forEach((a, k) => {
            if (k !== 'category' && k !== 'label'){
              params[k] = a;
            }
          })
          this.updateQueryStringParam(params)
          this.getProducts(e.target.getAttribute('data-link'), true)
        }
      })
    },
    getQueryString() {
      let params = {}
      let metaTitle = []
      params.f_attr = ''
      Object.keys(this.filters).forEach((f_key) => {
        Object.keys(this.filters[f_key].values).forEach((v_key) => {
          if (this.filters[f_key].values[v_key].checked) {
            if (this.filters[f_key].values[v_key].value.includes(',')) {
                this.filters[f_key].values[v_key].value = this.filters[f_key].values[v_key].value.replace(',', '.')
            }
            params.f_attr += '/' + slugify(replaceDiacritics(this.filters[f_key].name)) + '-f' + this.filters[f_key].id + ',' + this.replaceBadChar(slugify(replaceDiacritics(this.filters[f_key].values[v_key].value))) + '-v' + this.filters[f_key].values[v_key].id
            metaTitle.push(this.makeSlug(replaceDiacritics(this.filters[f_key].name)) + ' ' + this.makeSlug(replaceDiacritics(this.filters[f_key].values[v_key].value)))
          }
        })
      })
      if (Object.keys(this.flatBrandsFilter).length) {
        Object.keys(this.flatBrandsFilter).forEach((f_key) => {
          Object.keys(this.flatBrandsFilter[f_key].values).forEach((v_key) => {
            if (this.flatBrandsFilter[f_key].values[v_key].checked) {
              params.f_attr += '/' + slugify(replaceDiacritics(this.flatBrandsFilter[f_key].name)) + '-f' + this.flatBrandsFilter[f_key].values[v_key].id + ',' + slugify(replaceDiacritics(this.flatBrandsFilter[f_key].values[v_key].value)) + '-v' + this.flatBrandsFilter[f_key].values[v_key].id
              metaTitle.push(this.makeSlug(replaceDiacritics(this.flatBrandsFilter[f_key].name)) + ' ' + this.makeSlug(replaceDiacritics(this.flatBrandsFilter[f_key].values[v_key].value)))
            }
          })
        })
      }

      if (Object.keys(this.flatCategoryFilter).length) {
        Object.keys(this.flatCategoryFilter).forEach((f_key) => {
          Object.keys(this.flatCategoryFilter[f_key].values).forEach((v_key) => {
            if (this.flatCategoryFilter[f_key].values[v_key].checked) {
              params.f_attr += '/' + this.makeSlug(replaceDiacritics(this.flatCategoryFilter[f_key].name)) + '-f' + this.flatCategoryFilter[f_key].values[v_key].id + ',' + this.makeSlug(replaceDiacritics(this.flatCategoryFilter[f_key].values[v_key].value)) + '-v' + this.flatCategoryFilter[f_key].values[v_key].id
              metaTitle.push(this.makeSlug(replaceDiacritics(this.flatCategoryFilter[f_key].name)) + ' ' + this.makeSlug(replaceDiacritics(this.flatCategoryFilter[f_key].values[v_key].value)))
            }
          })
        })
      }

      if (this.orderBy !== 'relevance') {
        params.orderBy = this.orderBy
      }
      if (this.perPage !== 20) {
        params.perPage = this.perPage
      }
      if (this.fMinPrice !== null) {
        params.f_min_price = this.fMinPrice
      }
      if (this.fMaxPrice !== null) {
        params.f_max_price = this.fMaxPrice
      }

      const pageQueryParam = new URLSearchParams(window.location.search);
      params.q = pageQueryParam.get('q') || '';
      this.updateQueryStringParam(params)

      let baseUrl = [location.protocol, '//', location.host, location.pathname].join('')
      baseUrl = baseUrl.split('/filter')
      let queryString = '';
      if (baseUrl[1] && baseUrl[1].length > 0) {
        queryString = '/filter' + baseUrl[1]
      }
      queryString += window.location.search
      if (queryString && this.mainCateg) {
        queryString += '&category=' + this.mainCateg.id
      } else if (queryString === '' && this.mainCateg) {
        queryString += '?category=' + this.mainCateg.id
      }
      if (this.label) {
        if (queryString.length > 0) {
          queryString += '&label=' + this.label.id
        } else {
          queryString += '?label=' + this.label.id
        }
      }
      if (this.searchWords) {
        if (queryString.length > 0) {
          queryString += '&q=' + this.searchWords
        } else {
          queryString += '?q=' + this.searchWords
        }
      }
      return {q: queryString, metaTitle: metaTitle};
    },
    async updateFilters() {
      let queryString = this.getQueryString().q
      this.getProducts(this.routes.productsSearch + queryString, false, this.getQueryString().metaTitle)
    },
    setSelectFilter(keyFilter) {
      Object.keys(this.filters[keyFilter].values).forEach((v_key) => {
        this.filters[keyFilter].values[v_key].checked = false
      })

      let a = this.filters[keyFilter].values.find((v) => v.id === this.filters[keyFilter].selected)
      if (a) {
        a.checked = true
      }
      this.updateFilters()
    },
    resetFilters() {
      let params = {}
      params.f_attr = ''
      params.f_min_price = null
      params.f_max_price = null
      if (this.orderBy !== 'relevance') {
        params.orderBy = this.orderBy
      }
      if (this.perPage !== 20) {
        params.perPage = this.perPage
      }

      this.updateQueryStringParam(params)

      let queryString = window.location.search
      if (queryString && this.mainCateg) {
        queryString += '&category=' + this.mainCateg.id
      } else if (queryString === '' && this.mainCateg) {
        queryString += '?category=' + this.mainCateg.id
      }
      if (this.label) {
        if (queryString.length > 0) {
          queryString += '&label=' + this.label.id
        } else {
          queryString += '?label=' + this.label.id
        }
      }
      if (this.searchWords) {
        if (queryString.length > 0) {
          queryString += '&q=' + this.searchWords
        } else {
          queryString += '?q=' + this.searchWords
        }
      }
      if (this.$refs.sliderPrice) {
        this.$refs.sliderPrice.noUiSlider.updateOptions({
          start: [this.sliderPriceData.startMin, this.sliderPriceData.startMax],
        });
      }
      this.getProducts(this.routes.productsSearch + queryString)
      //$('#js-search-header-input').val('');
    },
    productEditLink(mp) {
      return this.routes.productEditAdmin+'/products/'+mp.product_id+'/edit?sku='+mp.sku_code+'#waypoint-microproduct'
    },
    resize() {
      if (window.isMobile) {
        this.isMobileView = true
        this.showShortDescription = false
        this.showMore = true;
      } else {
        this.isMobileView = false
      }
    },
    getBaseRoute(link) {
      let linkParts = link.split('/')
      linkParts.pop()
      return linkParts.join('/') + '/'
    },
    checkFreeShipping(mp) {
      let freeShipping = {
        message: '',
        free: false,
        badge: ''
      }

      let defaultShipping = mp.default_shipping;
      if (defaultShipping) {
        if (defaultShipping.free_shipping === 1) {
          freeShipping.free = true
          freeShipping.badge = 'TRANSPORT GRATUIT'
          freeShipping.message = `Acest produs beneficiază de Transport Gratuit.`
        } else if (defaultShipping.free_shipping_qty >= 1) {
          freeShipping.free = true
          freeShipping.badge = 'TRANSPORT GRATUIT'
          freeShipping.message = `Acest produs beneficiază de Transport Gratuit la comenzi de peste ${defaultShipping.free_shipping_qty} unități (${mp.measure_unit.name}).`
        }
      }
      return freeShipping;
    },
    makeSlug(string) {
      return string.split(' ').join('-')
    },
    replaceBadChar(string) {
      return string.replace('%', '')
    },
    toggleBrandCollapse(id) {
      this.localBrandsFilter.find((v) => v.id === id).collapsed = !this.localBrandsFilter.find((v) => v.id === id).collapsed
    },
    toggleCategoryCollapse(id) {
      this.localCategoriesFilter.find((v) => v.id === id).collapsed = !this.localCategoriesFilter.find((v) => v.id === id).collapsed
    },
    selectItemEvent(mp, redirectLink, index) {
      let mainCategoryName = '';
      let mainCategorySlug = '';
      if (this.mainCateg) {
        mainCategoryName = this.mainCateg.name
        mainCategorySlug = this.slugify(this.mainCateg.name)
      } else if (this.label) {
        mainCategoryName = this.label.name;
        mainCategorySlug = this.label.slug;
      } else if (this.searchWords) {
        mainCategoryName = 'Cautare dupa "' + this.searchWords + '"';
        mainCategorySlug = '';
      }

      let discount = 0;
      let price = mp.final_sell_price;
      if (mp.final_cut_sell_price > mp.final_sell_price) {
        discount = mp.final_cut_sell_price - mp.final_sell_price;
        price = mp.final_cut_sell_price;
      }


      this.GA4SelectItemEvent(
        mainCategorySlug,
        mainCategoryName,
        mp.sku_code,
        mp.name,
        mp.breadcrumbs || [],
        price,
        discount,
        index
      );
      setTimeout(function(){
        window.location.href = redirectLink;
      }, 100);
    },

    viewItemListEvent() {
      let mainCategoryName = '';
      let mainCategorySlug = '';

      if (this.mainCateg) {
        mainCategoryName = this.mainCateg.name
        mainCategorySlug = this.slugify(this.mainCateg.name)
      } else if (this.label) {
        mainCategoryName = this.label.name;
        mainCategorySlug = this.label.slug;
      } else if (this.searchWords) {
        mainCategoryName = 'Cautare dupa "' + this.searchWords + '"';
        mainCategorySlug = this.slugify(mainCategoryName);
      }

      if (this.paginationData && this.paginationData.current_page > 1) {
        mainCategoryName += ' - Pagina ' + this.paginationData.current_page;
      }

      let items = [];

      for (const [key, product] of Object.entries(this.products)) {
        let ratingNum = parseFloat((product.reviews_approved_sum || 0) / (product.reviews_approved_count || 1)).toFixed(2)
        let item = {
          "sku_code": product.sku_code,
          "name": product.name,
          "final_sell_price": product.final_sell_price,
          "final_cut_sell_price": product.final_cut_sell_price,
          "brand_name": product.brand_name,
          "item_reviews_count": product.reviews_approved_count,
          "item_reviews_score": ratingNum,
          "productCategories": product.breadcrumbs || []
        }
        items.push(item);
      }
      this.GA4ViewItemListEvent(
        mainCategorySlug,
        mainCategoryName,
        items
      );
    }
  },
  updated: function () {
    this.initPageChangeListener()
  }
}
</script>

<style scoped>
.container-faq{
    /*width: 1100px;*/

    margin: 0 auto;
    padding: 3px 0 20px 0;
}

.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    margin: 0 auto;
}

.active, .accordion:hover {
    background-color: #ccc;
}
.accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;

}
.active:after {
    content: "\2212";
}
.panel {
    padding: 0 18px;
    display: none;
    background-color: white;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s ease-out;
    -webkit-transition: max-height 0.4s ease-out;
    -moz-transition: max-height 0.4s ease-out;
    -ms-transition: max-height 0.4s ease-out;
    -o-transition: max-height 0.4s ease-out;
}
</style>
