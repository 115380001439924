window.showError = function(err, specificId = null, displayExceptions = []) {
    window.resetErrors()
    if (err.response && err.response.data) {
        let errors = err.response.data

        if (err.response.status === 401) {
            showJboxNotice("Vă rugăm să vă autentificați în contul dvs. pentru a putea face această acțiune.", "red")
            return
        }

        if (err.response.status === 404) {
            showJboxNotice("A apărut o eroare! <br>Vă rugăm să încercați din nou mai tarziu!", "red")
            return
        }

        if (err.response.status === 403) {
            showJboxNotice("Din păcate nu aveți permisiuni pentru această acțiune!", "red")
            return
        }

        if (err.response.status === 419) {
            showJboxNotice("A apărut o eroare! <br>Vă rugăm să încercați din nou mai tarziu!", "red")
            setTimeout(function() {
                window.location.reload()
            }, 3000)
            return
        }

        if (err.response.status === 500) {
            showJboxNotice("A apărut o eroare! <br>Vă rugăm să încercați din nou mai tarziu!", "red")
            return
        }

        if (err.response.data.messages) {
            errors = err.response.data.messages
        } else if (err.response.data.errors) {
            errors = err.response.data.errors
        }

        if (errors.constructor.name === "Object" || errors.constructor.name === "Array") {
            let err = ""
            Object.keys(errors).forEach(function(item) {
                let key = item.replace(/\./g, "-")
                let target_error = null
                if (specificId) {
                    target_error = $(`#${specificId} #${key}_error`)
                } else {
                    target_error = $(`#${key}_error`)
                }
                if (target_error.length) {
                    if (Array.isArray(errors[item])) {
                        let temp = ""
                        Object.keys(errors[item]).forEach(function(item2) {
                            if (Array.isArray(errors[item][item2])) {
                                Object.keys(errors[item][item2]).forEach(function(item3) {
                                    temp += errors[item][item2][item3] + "<br>"
                                })
                            } else {
                                temp += errors[item][item2] + "<br>"
                            }
                        })
                        target_error.html(temp).addClass("js-scroll-top")
                    } else {
                        target_error.html(errors[item]).addClass("js-scroll-top")
                    }
                } else {
                    let exception = false
                    displayExceptions.forEach(function(exceptionKey) {
                        if (key.includes(exceptionKey)) {
                            exception = true
                        }
                    })
                    if (!exception) {
                        err += errors[item] + "<br>"
                    }
                }
            })
            if (err.length > 0) {
                showJboxNotice(err, "red")
                $("html, body").animate({ scrollTop: 0 }, "slow")
                return false
            } else {
                showJboxNotice("Din păcate nu ați completat corect unele câmpuri. <br>Vă rugăm să verificați erorile afișate.", "yellow")
                $("html, body").animate(
                    {
                        scrollTop:
                            $(".js-custom-input-error.js-scroll-top")
                                .first()
                                .offset().top - 130,
                    },
                    "slow"
                )
            }
        } else {
            showJboxNotice(errors, "red")
        }
    } else {
        showJboxNotice("A apărut o eroare! <br>Vă rugăm să încercați din nou mai târziu!", "red")
    }
}

window.resetErrors = function(divId) {
    if (divId) {
        let targetDivError = $(divId + " .js-custom-input-error")
        targetDivError.html("")
        targetDivError.removeClass("js-scroll-top")
    } else {
        let targetDivError = $(".js-custom-input-error")
        targetDivError.html("")
        targetDivError.removeClass("js-scroll-top")
    }
}

window.showJboxNotice = function(content, color) {
    new jBox("Notice", { content: content, color: color })
}

window.prodFavEdit = function(link, product, action, from) {
    activateLoading()
    $(".js-fav-btn").attr("disabled", true)
    axios
        .post(link, {
            product: product,
            action: action,
        })
        .then(function(response) {
            let res = response.data.data

            if (res.redirect_to_login_link) {
                window.location.href = res.redirect_to_login_link
                return
            }

            if (action === "add") {
                let prices = calculatePriceAndDiscount(res.microProduct);
                GA4AddToWishlistEvent(
                  from,
                  from,
                  res.microProduct.sku_code,
                  res.microProduct.name,
                  res.microProductBreadcrumbs,
                  prices.price,
                  prices.discount,
                  res.subtotalWishlistFloat
                );
                showJboxNotice("Produsul a fost adăugat la favorite.", "green")
            } else if (action === "remove") {
                showJboxNotice("Produsul a fost șters de la favorite.", "blue")
            }
            if (action === "add" && from === "detail") {
                $(".js-add-fav").hide()
                $(".js-remove-fav").show()
            }
            if (action === "remove" && from === "detail") {
                $(".js-remove-fav").hide()
                $(".js-add-fav").show()
            }
            if (action === "remove" && from === "favourites") {
                window.location.reload()
            }
            $(".fav-prod-" + product).replaceWith(res.partial_icon_html)
            $(".js-fav-btn").attr("disabled", false)
            $("#js-favourite-header").html(res.favourite_header_html)
            dezactivateLoading()
        })
        .catch(function(error) {
            // handle error
            dezactivateLoading()
            showError(error)
        })
}

window.deleteProductFromCart = function(productId, rowId, subtotalId, totalId, groupId) {
    activateLoading()
    axios
        .post("/cart/remove-product", {
            productId: productId,
        })
        .then(function(response) {
            let res = response.data.data
            if (res.microProduct) {
                let prices = calculatePriceAndDiscount(res.microProduct);

                GA4RemoveFromCartEvent(
                  'cart',
                  'Cart',
                  res.microProduct.sku_code,
                  res.microProduct.name,
                  res.microProductBreadcrumbs,
                  prices.price,
                  prices.discount,
                  res.removed_qty,
                  res.microProduct.brand_name,
                  res.microProduct.final_sell_price
                );
            }

            dezactivateLoading()
            if (res.qty === 0) {
                window.location.reload()
            } else {
                $("#js-cart-header").html(res.cart_header_html)
                $('#js-cart-summary-section').html(res.cart_summary)
                if (rowId) {
                    let row = $("#" + rowId.replace(/(:|\.|\[|\]|,|=|@)/g, "\\$1"))
                    let group = row.parent(".cart-group");
                    group.find(".js-group-subtotal")
                        .html(res.warehouseSubtotal + " Lei")
                    group.find(".js-group-products-price")
                        .html(res.warehouseProductsPrice + " Lei")
                    group.find(".js-group-shipping-price")
                        .html(res.warehouseShippingPrice)
                    row.remove()

                    if (group.find(".cart-item").length === 0) {
                        group.remove();
                    }
                }
                if (subtotalId) {
                    $("#" + subtotalId).html(res.subtotal + " Lei")
                }
                if (totalId) {
                    $("#" + totalId).html(res.total + "Lei")
                }
                if (groupId) {
                    if (res.warehouseSubtotal.length <= 0) {
                        $("#" + groupId).remove()
                    }
                }
            }
        })
        .catch(function(error) {
            // handle error
            dezactivateLoading()
            showError(error)
        })
}

window.addToCart = function(microProductId, reloadPage, pageTitle) {
    activateLoading()
    let attrPriceMultiplier = document.querySelector("#attribute_price_multiplier") ? document.querySelector("#attribute_price_multiplier").value : 1
    let attrPriceMultiplierUm = document.querySelector("#attribute_price_multiplier_um") ? document.querySelector("#attribute_price_multiplier_um").value : ""
    let attrPriceMultiplierName = document.querySelector("#attribute_price_multiplier_name") ? document.querySelector("#attribute_price_multiplier_name").value : ""
    axios
        .get("/cart/add", {
            params: {
                microProductId: microProductId,
                attrPriceMultiplier: attrPriceMultiplier,
                attrPriceMultiplierUm: attrPriceMultiplierUm,
                attrPriceMultiplierName: attrPriceMultiplierName,
            },
        })
        .then(function(response) {
            let res = response.data.data
            let prodName = res.microProduct.name;
            if (attrPriceMultiplierUm) {
                prodName = res.microProduct.name+' '+attrPriceMultiplierName+' '+attrPriceMultiplier+' '+attrPriceMultiplier
            }
            if (!pageTitle) {
                pageTitle = 'Pagina Produs - '+res.microProduct.name
            }

            let prices = calculatePriceAndDiscount(res.microProduct);

            GA4AddToCartEvent(
              slugify(pageTitle),
              pageTitle,
              res.microProduct.sku_code,
              prodName,
              res.microProductBreadcrumbs,
              prices.price,
              prices.discount,
              res.added_qty,
              res.microProduct.brand_name,
              res.microProduct.final_sell_price
            );

            $("#js-cart-header").html(res.cart_header_html)
            if (reloadPage) {
                window.location.reload()
            } else {
                dezactivateLoading()
                let addToCartModal = new jBox("Modal", {
                    maxWidth: 768,
                    closeButton: "title",
                    adjustDistance: { top: 20, right: 15, bottom: 20, left: 15 },
                    onCreated: async function() {
                        await this.setContent(res.popup)
                    },
                    onCloseComplete() {
                        addToCartModal.destroy()
                    },
                })
                addToCartModal.setTitle("Produsul a fost adăugat în coș")
                addToCartModal.setWidth(768)
                addToCartModal.open()
                initCartModalSlider()
            }
        })
        .catch(function(error) {
            // handle error
            dezactivateLoading()
            showError(error)
        })
}

window.searchProducts = function(e) {
    let searchSubmit = $("#js-search-submit")
    if (e.type === "input") {
        var that = $(this)
        let words = that.val()
        let productsList = $("#js-search-header-products-list")
        let categoriesList = $("#js-search-header-categories-list")
        let productsSection = $("#js-search-header-products-section")
        let searchDropdown = $("#js-search-header-dropdown")
        let categoriesSection = $("#js-search-header-categories-section")

        if (words && words.length >= 2) {
            that.off("input")
            that.addClass("active")
            productsList.html("")
            categoriesList.html("")
            searchDropdown.slideDown("fast")
            axios
                .get("/products/search-header", {
                    params: {
                        q: words,
                    },
                })
                .then(function(response) {
                    let res = response.data.data
                    res.products.forEach(function(element) {
                        let href = decodeURI(element.link)
                            .replace(/″/g, "")
                            .replace(/ /g, "+")
                        let lastIndexOfChar = href.lastIndexOf("cauta")
                        // plus 5 because "cauta" has length 5
                        let search = href.slice(lastIndexOfChar, lastIndexOfChar + 5)
                        if (search === "cauta") {
                            // plus 6 because we must include the trailing "/" after "cauta"
                            href = href.slice(0, lastIndexOfChar + 6) + href.slice(lastIndexOfChar + 6).replaceAll("/", "++")
                        }
                        productsList.append(`<li><a href='${href}' title='${element.name}'>${element.name}</a></li>`)
                    })

                    if (res.products.length > 0) {
                        productsSection.show()
                    } else {
                        productsSection.hide()
                    }
                    res.categories.forEach(function(element) {
                        categoriesList.append(`<li><a href='${element.link}' title='${element.name}'>${element.name}</a></li>`)
                    })

                    if (res.categories.length > 0) {
                        categoriesSection.show()
                    } else {
                        categoriesSection.hide()
                    }
                    that.on("input", searchProducts)
                })
                .catch(function(error) {
                    that.on("input", searchProducts)
                })
        } else {
            that.removeClass("active")
            searchDropdown.slideUp("slow")
        }
    } else if (e.type === "keyup" && e.keyCode === 13) {
        const searchedTerm = e.target.value.replace(/\//g,' ').replace(/ /g,'+');
        window.PushSearchGAEvent(searchedTerm);
        window.location.href = `/cauta/${encodeURI(searchedTerm)}`;
    } else {
        const searchedTerm = e.target.value.replace(/\//g,' ').replace(/ /g,'+');
        searchSubmit.on("click", function(e) {
            e.preventDefault();
            window.PushSearchGAEvent(searchedTerm);
            window.location.href = `/cauta/${encodeURI(searchedTerm)}`;
        })
    }
}

window.slugify = function(text) {
    return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w\-]+/g, "") // Remove all non-word chars
        .replace(/\-\-+/g, "-") // Replace multiple - with single -
}

window.updateQueryStringParam = function(urlParams) {
    let baseUrl = [location.protocol, "//", location.host, location.pathname].join("")
    baseUrl = baseUrl.split("/filter")
    baseUrl = baseUrl[0]
    let filtered = Object.entries(urlParams).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
    let params = ""
    if (filtered.f_attr) {
        if (!filtered.f_attr.startsWith('/')) {
            filtered.f_attr = '/' + filtered.f_attr
        }
        params = "/filter" + filtered.f_attr
        delete filtered.f_attr
    }
    params += "?" + $.param(Object.assign({}, filtered), true)

    if (params === "?") {
        params = ""
    }
    // window.history.replaceState({}, "", baseUrl + params);
    window.history.pushState({}, "", baseUrl + params)
}

window.setCookie = function(cname, cvalue, exdays) {
    let d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

window.getCookie = function(cname) {
    let name = cname + "="
    let ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == " ") {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}

window.deleteCookie = function(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
}

window.getCitiesForCounty = async function(countyId) {
    activateLoading()
    let output = []
    await axios
        .post("/cities/get-by-county", {
            countyId: countyId,
        })
        .then((response) => {
            dezactivateLoading()
            output = response.data.data
        })
        .catch(function(error) {
            dezactivateLoading()
        })
    return output
}

// product sliders
window.initProductSliders = function() {
    window.productSliders = document.querySelectorAll(".js-product-item-slider")
    if (productSliders.length > 0) {
        for (var i = 0; i < productSliders.length; i++) {
            let productSlider = tns({
                container: productSliders[i],
                mouseDrag: true,
                swipeAngle: false,
                speed: 400,
                items: 2.5,
                loop: false,
                slideBy: "page",
                lazyload: true,
                navPosition: "bottom",
                // onInit: enableSlider(productSliders[i]),

                responsive: {
                    "475": {
                        items: 3,
                    },
                    "768": {
                        items: 4,
                    },
                    "1024": {
                        items: 5,
                    },
                    "1200": {
                        items: 6,
                    },
                },
            })
        }
    }

    function enableSlider(el) {
        console.log(el)
    }
}

window.initCartModalSlider = function() {
    let div = $(".js-cart-modal-slider")
    if (div.length === 0) {
        return
    }
    tns({
        container: ".js-cart-modal-slider",
        mouseDrag: true,
        swipeAngle: false,
        speed: 800,
        items: 2.5,
        loop: false,
        lazyload: true,
        slideBy: "page",
        navPosition: "bottom",

        responsive: {
            "475": {
                items: 3,
            },
            "768": {
                items: 4,
            },
        },
    })
}

window.initProductMenu = function() {
    const mobileMenuTrigger = document.querySelector(".js-mobile-menu-trigger")
    const mobileMenuMask = document.querySelector(".js-mobile-menu-mask")
    const productMenuTrigger = document.querySelector(".js-product-menu-trigger")
    const productMenuContainer = document.querySelector(".js-product-menu-container")
    const productMenu = document.querySelector(".js-product-menu")
    const productMenuLinks = productMenu.querySelectorAll("a")
    const productMenuMobileLinks = productMenu.querySelectorAll("[data-mobile-menu]")
    const productSubMenuContainer = document.querySelector(".js-product-submenu-container")
    const productSubMenus = document.querySelectorAll(".js-product-submenu")
    const body = document.querySelector("body")

    let productMenuHover = false
    let mobileMenuOpen = false

    mobileMenuTrigger.addEventListener("click", mobileMenuHandler)
    mobileMenuMask.addEventListener("click", mobileMenuHandler)

    function mobileMenuHandler() {
        if (!mobileMenuOpen) {
            activateItem(productMenuContainer)
            activateItem(mobileMenuTrigger)
            $("body").addClass("mobile-menu-open")
            mobileMenuOpen = true
        } else {
            deactivateItem(productMenuContainer)
            deactivateItem(mobileMenuTrigger)
            $("body").removeClass("mobile-menu-open")
            mobileMenuOpen = false
        }
    }

    if (body.classList.contains("homepage")) {
        productMenuTrigger.classList.add("active")
    }

    productMenuLinks.forEach(function(productMenuItem, index) {
        productMenuItem.addEventListener("mouseenter", () => {
            if (isMobileMenu) {
                return
            }
            activateItem(productMenuItem)
        })
        productMenuItem.addEventListener("mouseleave", () => {
            if (isMobileMenu) {
                return
            }
            deactivateItem(productMenuItem)
        })

        let opts = {
            timeout: 300,
        }
        //hoverintent
        hoverintent(
            productMenuItem,
            function() {
                if (isMobileMenu) {
                    return
                }
                // Handler in
                productSubMenuContainer.classList.add("active")
                toggleSubMenu(index + 1)
            },
            function() {
                // Handler out
            }
        ).options(opts)
    })

    productMenuMobileLinks.forEach(function(menuArrow, index) {
        menuArrow.addEventListener("click", () => {
            if (isMobileMenu) {
                toggleSubMenu(index + 1)
            }
        })
    })

    productMenuContainer.addEventListener("mouseenter", () => {
        productMenuHover = true
        activateItem(productMenuContainer)
        activateItem(productMenuTrigger)
    })

    // dezactivez meniu daca ies din el
    productMenuContainer.addEventListener("mouseleave", () => {
        if (isMobileMenu) {
            return
        }
        productMenuHover = false
        deactivateItem(productSubMenuContainer)
        removeActive(productMenuLinks)
        removeActive(productSubMenus)
        deactivateItem(productMenu)
        if (!body.classList.contains("homepage")) {
            deactivateItem(productMenuTrigger)
            deactivateItem(productMenuContainer)
        }
    })

    productSubMenus.forEach(function(element, index) {
        element.addEventListener("mouseenter", () => {
            activateItem(element)
            activateItem(productMenuLinks[index])
            activateItem(productMenu)
        })
    })

    function activateItem(element) {
        element.classList.add("active")
    }

    function deactivateItem(element) {
        element.classList.remove("active")
    }

    function toggleSubMenu(index) {
        removeActive(productMenuLinks)
        removeActive(productSubMenus)
        let subMenu = document.querySelector("[data-submenu='" + index + "']")
        let subMenuLink = document.querySelector("[data-menu='" + index + "']")
        activateItem(productSubMenuContainer)
        activateItem(subMenu)
        activateItem(subMenuLink)
    }

    function removeActive(group) {
        group.forEach(function(element, index) {
            deactivateItem(element)
        })
    }

    productMenuTrigger.addEventListener("mouseover", () => {
        activateItem(productMenuContainer)
        activateItem(productMenuTrigger)
    })

    productMenuTrigger.addEventListener("mouseleave", () => {
        if (!productMenuHover) {
            deactivateItem(productMenuContainer)
        }
        if (!body.classList.contains("homepage")) {
            deactivateItem(productMenuTrigger)
        }
    })

    $(".js-mobile-submenu-trigger").on("click", function() {
        if (
            $(this)
                .next(".js-product-menu-subsubmenu")
                .is(":visible")
        ) {
            $(this).removeClass("active")
            $(this)
                .next(".js-product-menu-subsubmenu")
                .slideUp("fast")
        } else {
            $(".js-mobile-submenu-trigger").removeClass("active")
            $(".js-product-menu-subsubmenu").slideUp("fast")
            $(this).addClass("active")
            $(this)
                .next(".js-product-menu-subsubmenu")
                .slideDown("fast")
        }
    })

    $(".js-product-submenu-back").on("click", function(e) {
        if (isMobileMenu) {
            e.preventDefault()
            $(".js-product-submenu-container").removeClass("active")
            $(".js-product-submenu-trigger").removeClass("active")
            setTimeout(function() {
                $(this)
                    .closest("[data-submenu]")
                    .removeClass("active")
            }, 300)
        }
    })
}

window.initMobileSearchToggle = function() {
    let mobileSearcToggle = $(".js-search-toggle")
    let mobileSearchOn = false
    let searchDropdown = $("#js-search-header-dropdown")
    let searchInput = $("#js-search-header-input")
    let searchBox = $(".js-search")

    mobileSearcToggle.on("click", function() {
        if (isMobile) {
            if (!mobileSearchOn) {
                mobileSearchOn = true
                searchBox.slideDown("fast")
                searchInput.trigger("focus")
            } else {
                mobileSearchOn = false
                searchBox.slideUp("fast")
                searchInput.trigger("blur")
            }
        }
    })

    window.addEventListener("click", function(e) {
        if (isMobile && mobileSearchOn) {
            if (outsideClick(e, searchBox) && outsideClick(e, searchDropdown) && outsideClick(e, mobileSearcToggle)) {
                e.preventDefault
                hideSearch()
                mobileSearchOn = false
            }
        }
    })
}

window.hideSearch = function() {
    let searchDropdown = $("#js-search-header-dropdown")
    let searchInput = $("#js-search-header-input")
    let searchBox = $(".js-search")
    searchDropdown.slideUp("fast")
    searchInput.removeClass("active")
    if (isMobile) {
        searchBox.slideUp("fast")
    }
}

// $('#js-search-header-input').on('blur', hideSearch);

window.outsideClick = function(event, notelem) {
    notelem = $(notelem) // jquerize (optional)
    // check outside click for multiple elements
    var clickedOut = true,
        i,
        len = notelem.length
    for (i = 0; i < len; i++) {
        if (event.target == notelem[i] || notelem[i].contains(event.target)) {
            clickedOut = false
        }
    }
    if (clickedOut) return true
    else return false
}

document.addEventListener("DOMContentLoaded", function() {
    $("#js-search-header-input").on("input keyup", searchProducts)
    initProductSliders()
    initProductMenu()
    initMobileFilters()
    initMobileSearchToggle()
})

$("#js-search-header-input").keyup(function(event) {
    if (event.which === 13) {
        const searchedTerm = event.target.value;
        window.PushSearchGAEvent(searchedTerm);
        window.location.href = `/cauta/${searchedTerm}`;
    }
})

function resizeMenuDropdown() {
    const menuWrapper = document.querySelector(".js-menu-wrapper")
    const productSubMenuContainer = document.querySelector(".js-product-submenu-container")
    const productMenuContainer = document.querySelector(".js-product-menu-container")

    if (isMobileMenu) {
        productMenuContainer.classList.remove("active")
        return
    }
    let widthMaster = menuWrapper.offsetWidth
    let dropdownWidth = widthMaster - 360
    productSubMenuContainer.style.width = dropdownWidth + "px"
}

window.initMobileFilters = function() {
    let filtersToggle = document.querySelector(".js-filters-toggle")
    let filtersContainer = document.querySelector(".js-filters")
    let filtersHide = document.querySelector(".js-hide-filters")

    if (filtersToggle) {
        filtersToggle.addEventListener("click", function() {
            if (isMobile) {
                filtersContainer.classList.add("active")
            }
        })
    }

    if (filtersHide) {
        filtersHide.addEventListener("click", function() {
            if (isMobile) {
                filtersContainer.classList.remove("active")
            }
        })
    }
}

// detect mobile
window.isMobileDetector = document.querySelector(".js-is-mobile")
window.isMobileMenuDetector = document.querySelector(".js-is-mobile-menu")
window.isMobile = false
window.isMobileMenu = false

function isHidden(el) {
    return el.offsetParent === null
}

function detectMobileTransform() {
    window.isMobile = !isHidden(isMobileDetector)
    window.isMobileMenu = !isHidden(isMobileMenuDetector)
}

detectMobileTransform()
resizeMenuDropdown()

window.addEventListener("resize", function() {
    detectMobileTransform()
    resizeMenuDropdown()
})

window.activateLoading = function() {
    $("body").addClass("has-overlay")
}

window.dezactivateLoading = function() {
    $("body").removeClass("has-overlay")
}

window.replaceDiacritics = function(str) {
    return str.normalize("NFKD")
}

window.GA4SelectItemEvent = function (
    itemListID,
    itemListName,
    itemID,
    itemName,
    productCategories,
    productPrice,
    productDiscount,
    productIndex,
) {
    if (process.env.MIX_APP_ENV !== 'production') {
        return;
    }

    let objCategories = {};
    let customerGroup = 'NOT LOGGED IN';
    let userId = getCookieValue('mesteresti_user_id');

    if (userId) {
        customerGroup = 'Client';
    }

    if (productCategories.length > 0) {
      objCategories = getItemCategories(productCategories);
    }

    let productCategoryNameSnakeCased = toSnakeCase(' ',productCategories.slice(-1)[0].name);
    let itemListIdSnakeCased = toSnakeCase('-', itemListID);

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "select_item",
    ecommerce: {
      item_list_id: itemListID,
      item_list_name: itemListIdSnakeCased,
      items: [
        Object.assign({
          item_id: itemID,
          item_name: itemName,
          affiliation: "Google Merchandise Store",
          discount: productDiscount,
          index: productIndex,
          item_brand: "",
          item_list_id: productCategoryNameSnakeCased,
          item_list_name: itemListName,
          item_variant: itemID,                 // cea mai facila si viabila solutie este cod produs
          location_id: process.env.MIX_LOCATION_ID,
          price: productPrice,
          quantity: 1,
          google_business_vertical: 'retail'            // custom daca da ok Alex Ads
        }, objCategories)
      ]
    }
  });
  window.dataLayer.push({
      user_id: userId ?? ""
  });
  window.dataLayer.push({
      customerGroup: customerGroup
  });
}

window.GA4AddToWishlistEvent = function (
  itemListID,
  itemListName,
  itemID,
  itemName,
  productCategories,
  productPrice,
  productDiscount,
  ecommerceValue,
) {
    if (process.env.MIX_APP_ENV !== 'production') {
      return;
    }
    let objCategories = {};
    let customerGroup = 'NOT LOGGED IN';
    let userId = getCookieValue('mesteresti_user_id');

    if (userId) {
        customerGroup = 'Client';
    }

    if (productCategories.length > 0) {
        objCategories = getItemCategories(productCategories);
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "add_to_wishlist",
        ecommerce: {
            currency: 'RON',
            value: ecommerceValue,
            items: [
                Object.assign({
                    item_id: itemID,
                    item_name: itemName,
                    affiliation: "Google Merchandise Store",
                    discount: productDiscount,
                    index: 0,
                    item_brand: "",
                    item_list_id: itemListID,
                    item_list_name: itemListName,
                    item_variant: itemID,                 // cea mai facila si viabila solutie este cod produs
                    location_id: process.env.MIX_LOCATION_ID,
                    price: productPrice,
                    quantity: 1,
                    google_business_vertical: 'retail'            // custom daca da ok Alex Ads
                }, objCategories)
            ]
        }
    });
    window.dataLayer.push({
        user_id: userId ?? ""
    });
    window.dataLayer.push({
        customerGroup: customerGroup
    });
}

window.GA4ViewItemEvent = function (
  itemListID,
  itemListName,
  itemID,
  itemName,
  productCategories,
  reducedProductPrice,
  initialSellPrice,
  productDiscount,
  productBrandName,
) {
    if (process.env.MIX_APP_ENV !== 'production') {
      return;
    }
    let objCategories = {};
    let customerGroup = 'NOT LOGGED IN';
    let userId = getCookieValue('mesteresti_user_id');

    if (userId) {
        customerGroup = 'Client';
    }

    if (productCategories.length > 0) {
        objCategories = getItemCategories(productCategories);
    }

    let productCategoryNameSnakeCased = toSnakeCase(' ',productCategories.slice(-1)[0].name);
    let productCategoryName = productCategories.slice(-1)[0].name;

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "view_item",
        ecommerce: {
            currency: 'RON',
            value: reducedProductPrice,
            items: [
                Object.assign({
                    item_id: itemID,
                    item_name: itemName,
                    affiliation: "Google Merchandise Store",
                    discount: productDiscount,
                    index: 0,
                    item_brand: productBrandName ?? "",
                    item_list_id: productCategoryNameSnakeCased,
                    item_list_name: productCategoryName,
                    item_variant: itemID,                 // cea mai facila si viabila solutie este cod produs
                    location_id: process.env.MIX_LOCATION_ID,
                    price: initialSellPrice,
                    quantity: 1,
                    google_business_vertical: 'retail'            // custom daca da ok Alex Ads
                }, objCategories)
            ]
        }
    });
    window.dataLayer.push({
        user_id: userId ?? ""
    });
    window.dataLayer.push({
        customerGroup: customerGroup
    });
}

window.GA4AddToCartEvent = function (
  itemListID,
  itemListName,
  itemID,
  itemName,
  productCategories,
  productPrice,
  productDiscount,
  productQuantity,
  productBrandName,
  ecommerceValue,
) {
    if (process.env.MIX_APP_ENV !== 'production') {
      return;
    }

    let objCategories = {};
    let customerGroup = 'NOT LOGGED IN';
    let userId = getCookieValue('mesteresti_user_id');

    if (userId) {
        customerGroup = 'Client';
    }

    if (productCategories.length > 0) {
        objCategories = getItemCategories(productCategories);
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            currency: 'RON',
            value: ecommerceValue,
            items: [
                Object.assign({
                    item_id: itemID,
                    item_name: itemName,
                    affiliation: "Google Merchandise Store",
                    discount: productDiscount,
                    index: 0,
                    item_brand: productBrandName ?? "",
                    item_list_id: itemListID,
                    item_list_name: itemListName,
                    item_variant: itemID,                 // cea mai facila si viabila solutie este cod produs
                    location_id: process.env.MIX_LOCATION_ID,
                    price: productPrice,
                    quantity: productQuantity,
                    google_business_vertical: 'retail'            // custom daca da ok Alex Ads
                }, objCategories)
            ]
        }
    });
    window.dataLayer.push({
        user_id: userId ?? ""
    });
    window.dataLayer.push({
        customerGroup: customerGroup
    });
}

window.GA4RemoveFromCartEvent = function (
  itemListID,
  itemListName,
  itemID,
  itemName,
  productCategories,
  productPrice,
  productDiscount,
  productQuantity,
  productBrandName,
  ecommerceValue
) {
    if (process.env.MIX_APP_ENV !== 'production') {
      return;
    }
    let objCategories = {};
    let customerGroup = 'NOT LOGGED IN';
    let userId = getCookieValue('mesteresti_user_id');

    if (userId) {
        customerGroup = 'Client';
    }
    if (productCategories.length > 0) {
        objCategories = getItemCategories(productCategories);
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
            currency: 'RON',
            value: ecommerceValue,
            items: [
                Object.assign({
                    item_id: itemID,
                    item_name: itemName,
                    affiliation: "Google Merchandise Store",
                    discount: productDiscount,
                    index: 0,
                    item_brand: productBrandName ?? "",
                    item_list_id: itemListID,
                    item_list_name: itemListName,
                    item_variant: itemID,                 // cea mai facila si viabila solutie este cod produs
                    location_id: process.env.MIX_LOCATION_ID,
                    price: productPrice,
                    quantity: productQuantity,
                    google_business_vertical: 'retail'            // custom daca da ok Alex Ads
                }, objCategories)
            ]
        }
    });
    window.dataLayer.push({
        user_id: userId ?? ""
    });
    window.dataLayer.push({
        customerGroup: customerGroup
    });
}

window.GA4ViewCartEvent = function (
  ecommerceValue,
  items
) {
    if (process.env.MIX_APP_ENV !== 'production') {
      return;
    }

    let formattedItems = [];
    let customerGroup = 'NOT LOGGED IN';
    let userId = getCookieValue('mesteresti_user_id');

    if (userId) {
        customerGroup = 'Client';
    }
    items.forEach((microProduct, index) => {
        let objCategories = {};

        if (microProduct.productCategories.length > 0) {
            objCategories = getItemCategories(microProduct.productCategories);
        }

        let prices = calculatePriceAndDiscount(microProduct);

        let formattedProduct = Object.assign({
            item_id: microProduct.sku_code,
            item_name: microProduct.name,
            affiliation: "Google Merchandise Store",
            discount: prices.discount,
            index: index+1,
            item_brand: microProduct.brand_name ?? "",
            item_list_id: 'checkout',
            item_list_name: 'Checkout',
            item_variant: microProduct.sku_code,  // cea mai facila si viabila solutie este cod produs
            location_id: process.env.MIX_LOCATION_ID,
            price: prices.price,
            quantity: microProduct.quantity,
            google_business_vertical: 'retail'            // custom daca da ok Alex Ads
        }, objCategories)
        formattedItems.push(formattedProduct);
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "view_cart",
        ecommerce: {
            currency: 'RON',
            value: ecommerceValue,
            items: formattedItems
        }
    });
    window.dataLayer.push({
        user_id: userId ?? ""
    });
    window.dataLayer.push({
        customerGroup: customerGroup
    });
}

window.GA4BeginCheckoutEvent = function (
  ecommerceValue,
  items
) {
    if (process.env.MIX_APP_ENV !== 'production') {
      return;
    }
    let formattedItems = [];
    let customerGroup = 'NOT LOGGED IN';
    let userId = getCookieValue('mesteresti_user_id');

    if (userId) {
        customerGroup = 'Client';
    }
    items.forEach((microProduct, index) => {
        let objCategories = {};

        if (microProduct.productCategories.length > 0) {
            objCategories = getItemCategories(microProduct.productCategories);
        }

        let prices = calculatePriceAndDiscount(microProduct);

        let formattedProduct = Object.assign({
            item_id: microProduct.sku_code,
            item_name: microProduct.name,
            affiliation: "Google Merchandise Store",
            discount: prices.discount,
            index: index+1,
            item_brand: microProduct.brand_name ?? "",
            item_list_id: 'checkout',
            item_list_name: 'Checkout',
            item_variant: microProduct.sku_code,  // cea mai facila si viabila solutie este cod produs
            location_id: process.env.MIX_LOCATION_ID,
            price: prices.price,
            quantity: microProduct.quantity,
            google_business_vertical: 'retail'            // custom daca da ok Alex Ads
        }, objCategories)
        formattedItems.push(formattedProduct);
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
            currency: 'RON',
            value: ecommerceValue,
            items: formattedItems
        }
    });
    window.dataLayer.push({
        user_id: userId ?? ""
    });
    window.dataLayer.push({
        customerGroup: customerGroup
    });
}

window.GA4AddPaymentInfoEvent = function (
  ecommerceValue,
  paymentType,
  items
) {
    if(process.env.MIX_APP_ENV !== 'production') {
      return;
    }
    let formattedItems = [];
    let customerGroup = 'NOT LOGGED IN';
    let userId = getCookieValue('mesteresti_user_id');

    if (userId) {
        customerGroup = 'Client';
    }
    items.forEach((microProduct, index) => {
        let objCategories = {};

        if (microProduct.productCategories.length > 0) {
            objCategories = getItemCategories(microProduct.productCategories);
        }

        let prices = calculatePriceAndDiscount(microProduct);

        let formattedProduct = Object.assign({
            item_id: microProduct.sku_code,
            item_name: microProduct.name,
            affiliation: "Google Merchandise Store",
            discount: prices.discount,
            index: index+1,
            item_brand: microProduct.brand_name ?? "",
            item_list_id: 'checkout',
            item_list_name: 'Checkout',
            item_variant: microProduct.sku_code,  // cea mai facila si viabila solutie este cod produs
            location_id: process.env.MIX_LOCATION_ID,
            price: prices.price,
            quantity: microProduct.quantity,
            google_business_vertical: 'retail'            // custom daca da ok Alex Ads
        }, objCategories)
        formattedItems.push(formattedProduct);
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
            currency: 'RON',
            value: ecommerceValue,
            payment_type: paymentType,
            items: formattedItems
        }
    });
    window.dataLayer.push({
        user_id: userId ?? ""
    });
    window.dataLayer.push({
        customerGroup: customerGroup
    });
}

window.GA4AddShippingInfoEvent = function (
  ecommerceValue,
  shippingTier,
  items
) {
    if(process.env.MIX_APP_ENV !== 'production') {
        return;
    }
    let formattedItems = [];
    let customerGroup = 'NOT LOGGED IN';
    let userId = getCookieValue('mesteresti_user_id');

    if (userId) {
        customerGroup = 'Client';
    }
    items.forEach((microProduct, index) => {
        let objCategories = {};

        if (microProduct.productCategories.length > 0) {
            objCategories = getItemCategories(microProduct.productCategories);
        }

        let prices = calculatePriceAndDiscount(microProduct);

        let formattedProduct = Object.assign({
            item_id: microProduct.sku_code,
            item_name: microProduct.name,
            affiliation: "Google Merchandise Store",
            discount: prices.discount,
            index: index+1,
            item_brand: microProduct.brand_name ?? "",
            item_list_id: 'checkout',
            item_list_name: 'Checkout',
            item_variant: microProduct.sku_code,  // cea mai facila si viabila solutie este cod produs
            location_id: process.env.MIX_LOCATION_ID,
            price: prices.price,
            quantity: microProduct.quantity,
            google_business_vertical: 'retail'            // custom daca da ok Alex Ads
        }, objCategories)
        formattedItems.push(formattedProduct);
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
            currency: 'RON',
            value: ecommerceValue,
            shipping_tier: shippingTier,
            items: formattedItems
        }
    });
    window.dataLayer.push({
        user_id: userId ?? ""
    });
    window.dataLayer.push({
        customerGroup: customerGroup
    });
}

window.GA4PurchaseEvent = function (
  transactionId,
  ecommerceValue,
  tax,
  shippingValue,
  items
) {
    if(process.env.MIX_APP_ENV !== 'production') {
      return;
    }
    let formattedItems = [];
    let customerGroup = 'NOT LOGGED IN';
    let userId = getCookieValue('mesteresti_user_id');

    if (userId) {
        customerGroup = 'Client';
    }
    items.forEach((microProduct, index) => {
        let objCategories = {};

        if (microProduct.productCategories.length > 0) {
            objCategories = getItemCategories(microProduct.productCategories);
        }

        let prices = calculatePriceAndDiscount(microProduct);

        let formattedProduct = Object.assign({
            item_id: microProduct.sku_code,
            item_name: microProduct.name,
            affiliation: "Google Merchandise Store",
            discount: prices.discount,
            index: index+1,
            item_brand: microProduct.brand_name ?? "",
            item_list_id: 'checkout-success',
            item_list_name: 'Checkout Success',
            item_variant: microProduct.sku_code,  // cea mai facila si viabila solutie este cod produs
            location_id: process.env.MIX_LOCATION_ID,
            price: prices.price,
            quantity: microProduct.quantity,
            google_business_vertical: 'retail'            // custom daca da ok Alex Ads
        }, objCategories)
        formattedItems.push(formattedProduct);
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "purchase",
        ecommerce: {
            transaction_id: transactionId,
            currency: 'RON',
            value: ecommerceValue,
            tax: tax,
            shipping: shippingValue,
            items: formattedItems
        }
    });
    window.dataLayer.push({
        user_id: userId ?? ""
    });
    window.dataLayer.push({
        customerGroup: customerGroup
    });
}

window.GA4ViewItemListEvent = function (
  itemListID,
  itemListName,
  items
) {
    if(process.env.MIX_APP_ENV !== 'production') {
      return;
    }
    let formattedItems = [];
    let customerGroup = 'NOT LOGGED IN';
    let userId = getCookieValue('mesteresti_user_id');

    if (userId) {
        customerGroup = 'Client';
    }
    items.forEach((microProduct, index) => {
        let objCategories = {};

        if (microProduct.productCategories.length > 0) {
            objCategories = getItemCategories(microProduct.productCategories);
        }

        let prices = calculatePriceAndDiscount(microProduct);

        let productCategoryNameSnakeCased = toSnakeCase(' ',objCategories.item_category3);

        let formattedProduct = Object.assign({
            item_id: microProduct.sku_code,
            item_name: microProduct.name,
            affiliation: "Google Merchandise Store",
            discount: prices.discount,
            index: index+1,
            item_brand: microProduct.brand_name ?? "",
            item_list_id: productCategoryNameSnakeCased,
            item_list_name: itemListName,
            item_variant: microProduct.sku_code,  // cea mai facila si viabila solutie este cod produs
            location_id: process.env.MIX_LOCATION_ID,
            price: prices.price,
            quantity: 1,
            item_reviews_count: microProduct.item_reviews_count, // fara reviews return undefined
            item_reviews_score: microProduct.item_reviews_score, // return undefined
            google_business_vertical: 'retail'            // custom daca da ok Alex Ads
        }, objCategories)
        formattedItems.push(formattedProduct);
    });

    let itemListIdSnakeCased = '';

    if (hasWhiteSpace(itemListID)) {
        itemListIdSnakeCased = toSnakeCase(' ', itemListID);
    } else {
        itemListIdSnakeCased = toSnakeCase('-', itemListID);
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
            item_list_id: itemListIdSnakeCased,
            item_list_name: itemListName,
            items: formattedItems
        }
    });
    window.dataLayer.push({
        user_id: userId ?? ""
    });
    window.dataLayer.push({
        customerGroup: customerGroup
    });
}


window.GAaddToCartEvent = function(code, name, pageTitle, brand, category, qty, price) {
    if(process.env.MIX_APP_ENV !== 'production') {
        return;
    }
    gtag('event', 'add_to_cart', {
        "items": [
            {
                "id": code, // cod produs
                "name": name, // nume produs
                "list_name": pageTitle, // titlul paginii pe care era cand a bagat in cos (Tip pagina - Nume pagina, Categorie - Gard Beton)
                "brand": brand, // brandul produsului
                "category": category, // categoria produsului
                "variant": "", // necompletat
                "list_position": 1, // pe ce pozitie era pe pagina de unde a fost adaugat in cos
                "quantity": qty, // cantitatea (1 sau in caz ca sunt cantitati minime)
                "price": price // pretul fara tva (unitar)
            }
        ]
    });
}

window.GAremoveFromCartEvent = function(code, name, pageTitle, brand, category, qty, price) {
    if(process.env.MIX_APP_ENV !== 'production') {
        return;
    }
    gtag('event', 'remove_from_cart', {
        "items": [
            {
                "id": code, // cod produs
                "name": name, // nume produs
                "list_name": pageTitle, // titlul paginii pe care era cand a bagat in cos (Tip pagina - Nume pagina, Categorie - Gard Beton)
                "brand": brand, // brandul produsului
                "category": category, // categoria produsului
                "variant": "", // necompletat
                "list_position": 1, // pe ce pozitie era pe pagina de unde a fost adaugat in cos
                "quantity": qty, // cantitatea (1 sau in caz ca sunt cantitati minime)
                "price": price // pretul fara tva (unitar)
            }
        ]
    });
}

window.toSnakeCase = function (separator, string = '') {
    // console.log(string.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
    // string = string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return string.replace(/\d+/g, separator)
        .split(separator)
        .map((word) => word.toLowerCase())
        .join('_');
};

window.hasWhiteSpace =  function (string) {
    return string.indexOf(' ') >= 0;
}

window.calculatePriceAndDiscount = function (item) {
    let discount = 0;
    let price = item.final_sell_price;

    if (item.final_cut_sell_price > item.final_sell_price) {
        discount = item.final_cut_sell_price - item.final_sell_price;
        price = item.final_cut_sell_price;
    }

    return {discount: discount, price: price};
}

window.getItemCategories = function (productCategories) {
    let objCategories = {};

    productCategories.forEach((element, index) => {
        if (index === 0) {
            objCategories['item_category'] = element.name;
        } else {
            objCategories['item_category'+(index+1)] = element.name;
        }
    });

    return objCategories;
}

window.getCookieValue = function (name) {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
}

window.PushSearchGAEvent = function (searchedTerm) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'search',
        search_term: searchedTerm
    });
}
